*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  overflow: hidden;
  display: none;
}

*::-webkit-scrollbar-track {
  background: transparent;

}

*::-webkit-scrollbar-thumb {
  background-color: #cf0902;
  border-radius: 10px;
  border: 2px solid white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content1 {
  width: 640px;
  /* height: 480px; */
  /* padding: 5px 0px; */
  background-color: white;
  border-radius: 15px;
  overflow-y: scroll;
  /* max-height: calc(100vh - 60px); */
  margin-right: 40px;
}

.dialog-box {
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  overflow-y: scroll;
  max-height: calc(100vh - 50px);
  margin: 20px;

}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

@media screen and (max-width: 750px) {
  .modal-content1 {
    max-height: 500px;
  }

}

.btn {
  padding: 4px 15px;
  background-color: #cf0902;
  border: none;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  height: 32px;
}

.borderbtn {
  padding: 4px 15px;
  background-color: white;
  border: 1px solid #cf0902;
  color: #cf0902;
  border-radius: 6px;
  cursor: pointer;
  height: 32px;
}

.close-modal-btn {
  background-color: #cf0902;
  border: none;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  padding: 3px;
}

.btn:hover,
.close-modal-btn:hover {
  background-color: #d35c58d3;
}

@media screen and (max-width: 750px) {
  .modal-content1 {
    width: 100%;
    margin: 0px 10px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eaf6f0;
  border-radius: 5px 5px 0 0;
  padding: 10px 10px;
}

.body {
  padding: 10px;
  background-color: white;
}

.bodyimg {
  height: 250px;
  width: auto;
  object-fit: contain;
  text-align: center;
  border-radius: 10px;
}

.sidepart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rightsidepart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rightbtn {
  background-color: white;
  border: 1px solid rgb(180, 180, 180);
  color: black;
  border-radius: 20px 20px 0px 20px;
  cursor: pointer;
  padding: 14px 15px;
  width: 150px;
}

.rightbtn:hover {
  background-color: #cf0902;
  color: white;
}

.leftbtn {
  background-color: white;
  border: 1px solid rgb(180, 180, 180);
  color: black;
  border-radius: 20px 20px 20px 0px;
  cursor: pointer;
  padding: 14px 15px;
  width: 150px;
}

.leftbtn:hover {
  background-color: #cf0902;
  color: white;
}

@media screen and (max-width: 750px) {

  .leftbtn,
  .rightbtn {
    width: 50%;
  }
}

.gender {
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 5px;
}

.tabs {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 2px solid #ddd;
  flex-direction: column;
}

.tabs ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 2px solid #ddd;
  flex-direction: row;
}

.tabs li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: none;
  font-size: 14px;
}

.tabs li.active {
  color: black;
  border-bottom: 2px solid #cf0902;
}

.tab-content {
  border: 1px solid #ddd;
  border-top: none;
  transition: opacity 0.3s;
}

.table {
  display: none;
  max-width: 800px;
  overflow-x: scroll;
}

.table.right-fit-table,
.table.loose-fit-table,
.table.tight-fit-table {
  display: block;
}

.fit-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  max-width: 800px;
}

.fit-table th,
.fit-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  min-width: 100px;
}

.fit-table td {
  text-align: center;
  max-width: 200px;
  white-space: wrap;
  overflow: hidden;
  font-size: 14px;
}

.fit-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 14px;

}

.fit-table img {
  width: 50px;
  height: auto;
  border-radius: 4px;
}